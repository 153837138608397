import useSWR from 'swr';
import { fetchApiHub } from '@frontastic-engbers/lib/lib/fetch-api-hub';
import {
  EngbersConfigResponse,
  EngbersFlagsConfig,
  ShippingProviderConfig,
  MediaType,
  TrustedShopsConfig,
} from '@frontastic-engbers/types/engbers-custom';

export const getEcondaConfig = async () => {
  return await fetchApiHub('/action/config/getEcondaConfig', { method: 'POST' });
};

export const getTrackingConfig = async () => {
  return await fetchApiHub('/action/config/getTrackingConfig', { method: 'POST' });
};

export const getCleverPushConfig = async () => {
  return await fetchApiHub('/action/config/getCleverPushConfig', { method: 'POST' });
};

export const useEngbersFlagsConfig = () => {
  const { data, error, isLoading } = useSWR<EngbersConfigResponse>('/action/config/getEngbersConfig', fetchApiHub);

  return {
    isLoading,
    error,
    flagsConfig: data
      ? ({
          newFlagImage: data.newFlagImage,
          campaignFlags: data.campaignFlags ?? [],
        } as EngbersFlagsConfig)
      : undefined,
  };
};

export const useTrustedShopsConfig = () => {
  const { data } = useSWR<TrustedShopsConfig>('/action/config/getTrustedShopsConfig', fetchApiHub);

  return data;
};

export const useFallbackImage = () => {
  const { data, isLoading } = useSWR<MediaType>('/action/config/getFallbackImage', fetchApiHub);

  return {
    data,
    isLoading
  };
}

export const useSnippets = () => {
  const { data } = useSWR<Record<string, string>>('/action/config/getSnippets', {
    fetcher: fetchApiHub,
    fallbackData: {},
  });

  return data;
};

export const useShippingProvider = () => {
  const { data } = useSWR<ShippingProviderConfig>('/action/config/getShippingProvider', {
    fetcher: fetchApiHub,
    fallbackData: {},
  });

  return data;
};
