import React, { useEffect, useMemo, useState } from 'react';
import { InstantSearch, useHits } from 'react-instantsearch';
import { useRouter } from 'next/router';
import { PlainSearchParameters } from 'algoliasearch-helper';
import algoliasearch from 'algoliasearch/lite';
import { useInstantSearchClient } from '@frontastic-engbers/lib';
import LocalizedIndex from '@frontastic-engbers/lib/provider/algolia/localized-index';
import { queryProductsByIds } from '@frontastic-engbers/lib/actions/product';
import { useEngbersFlagsConfig } from '@frontastic-engbers/lib/actions/config';
import { Product } from '@frontastic-engbers/types/product/Product';
import { ProductMapper } from '@frontastic-engbers/lib/lib/mappers/productMapper';
import { CustomConfigure } from '@engbers/components/online-shops/product-list/components/custom-configure';
import { Markdown } from '@engbers/components';
import ProductSlider from '@engbers/components/online-shops/product-slider';
import { EcondaSlider } from '@engbers/components/online-shops/econda/econda-slider';
interface Props {
  data: ProductSliderData;
}
interface ProductSliderData {
  title?: string;
  textSize?: number;
  algoliaConfig?: any;
  productIds?: string;
  econdaWidgetConfig?: any;
  econdaSliderType: 'alternativeSlider' | 'matchingSlider';
  widgetId?: string;
}
interface AlgoliaSliderData extends ProductSliderData {
  defaultFilters: string;
  ruleContexts: string[];
}
export const ProductSliderTastic: React.FC<Props> = ({
  data
}) => {
  const {
    title,
    textSize,
    algoliaConfig,
    econdaWidgetConfig,
    econdaSliderType
  } = data;
  const defaultFilters = useMemo(() => {
    return algoliaConfig?.dataSource?.filters ?? '';
  }, [algoliaConfig?.dataSource?.filters]);
  const ruleContexts = useMemo(() => {
    return algoliaConfig?.dataSource?.ruleContexts ?? [];
  }, [algoliaConfig?.dataSource?.ruleContexts]);
  if (!defaultFilters && !ruleContexts.length && !econdaWidgetConfig?.dataSource?.credentials && !data.productIds) {
    return null;
  }
  return <div data-sentry-component="ProductSliderTastic" data-sentry-source-file="index.tsx">
      {title && <Markdown className="text-center uppercase" text={title} textSize={textSize} />}
      {algoliaConfig && (defaultFilters ? <AlgoliaSlider {...data} defaultFilters={defaultFilters} ruleContexts={ruleContexts} /> : <ProductIdsSlider {...data} defaultFilters={defaultFilters} ruleContexts={ruleContexts} />)}
      {econdaWidgetConfig?.dataSource?.credentials && !algoliaConfig && <EcondaSlider config={econdaWidgetConfig.dataSource.credentials} widgetId={data.widgetId || econdaWidgetConfig.dataSource[econdaSliderType]} textSize={textSize} title="" />}
    </div>;
};
const AlgoliaSlider: React.FC<AlgoliaSliderData> = ({
  defaultFilters,
  ruleContexts,
  ...data
}) => {
  const [searchParams, setSearchParams] = useState<PlainSearchParameters>(undefined);
  const searchClient = useInstantSearchClient(data.algoliaConfig.dataSource);
  algoliasearch(data.algoliaConfig.dataSource.appId, data.algoliaConfig.dataSource.appKey);
  useEffect(() => {
    setSearchParams({
      filters: defaultFilters,
      ruleContexts: ruleContexts,
      hitsPerPage: 16
    });
  }, []);
  return searchParams ? <InstantSearch searchClient={searchClient}>
      <LocalizedIndex config={data.algoliaConfig.dataSource}>
        <CustomConfigure params={searchParams} />
        <HitsSlider />
      </LocalizedIndex>
    </InstantSearch> : null;
};
const HitsSlider = () => {
  const {
    hits
  } = useHits();
  const router = useRouter();
  const [products, setProducts] = useState<Product[]>([]);
  const locale = router.locale || router.defaultLocale;
  const {
    flagsConfig,
    isLoading
  } = useEngbersFlagsConfig();
  useEffect(() => {
    if (!isLoading) {
      setProducts(hits.map(hit => ProductMapper.algoliaHitToProduct(hit, {
        locale,
        flagsConfig
      })).map(hit => ({
        ...hit,
        url: `https://www.engbers.com/${hit.url}`
      })));
    }
  }, [hits, isLoading]);
  if (products.length === 0) {
    return null;
  }
  return <ProductSlider products={products} useHoverBox={true} data-sentry-element="ProductSlider" data-sentry-component="HitsSlider" data-sentry-source-file="index.tsx" />;
};
const ProductIdsSlider = (data: AlgoliaSliderData) => {
  const ids = data.productIds.split(';');
  const [products, setProducts] = useState<Product[]>([]);
  useEffect(() => {
    if (ids.length > 0) {
      queryProductsByIds(ids).then(items => {
        setProducts(items);
      });
    }
  }, [data.productIds]);
  if (products.length === 0) {
    return null;
  }
  return <ProductSlider products={products} data-sentry-element="ProductSlider" data-sentry-component="ProductIdsSlider" data-sentry-source-file="index.tsx" />;
};