import React, { useMemo } from 'react';
import classnames from 'classnames';
import type { Swiper } from 'swiper/types';
import { useMediaQuery } from 'react-responsive';
import { getProjectInfo } from '@frontastic-engbers/lib';
import { ProductUtils } from '@frontastic-engbers/helpers/utils/productUtils';
import { Reference, ReferenceLink } from '@frontastic-engbers/helpers/reference';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Markdown } from '@engbers/components';
import { Slider, SliderProps } from '@engbers/components/slider';
import { ProductBox } from '../product-list/components/product-box';
import { OutfitProductBox } from '../product-list/components/outfit-product-box';
import styles from './product-slider.module.scss';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
export interface Props {
  products?: Product[];
  title?: string;
  textSize?: number;
  ctaLabel?: string;
  ctaLink?: Reference;
  isStartPage?: boolean;
  hasArrows?: boolean;
  hasDots?: boolean;
  useHoverBox?: boolean;
  customTitleStyle?: object;
  isOutfitSlider?: boolean;
  isTopSellerSlider?: boolean;
  productListPage?: number;
  slidesPerView?: {
    mobile: number;
    desktop: number;
  };
  onSelect?: () => void;
  fallbackImage?: MediaType;
  tabIndex?: number;
}
export default function ProductSlider({
  products,
  title,
  textSize,
  ctaLabel,
  ctaLink,
  isStartPage = false,
  hasArrows = true,
  hasDots = false,
  useHoverBox = false,
  customTitleStyle,
  isOutfitSlider = false,
  isTopSellerSlider,
  productListPage,
  slidesPerView = {
    mobile: 2.3,
    desktop: 4
  },
  onSelect,
  fallbackImage,
  tabIndex = 0
}: Props) {
  const isMobileSize = useMediaQuery({
    minWidth: 468
  });
  const isDesktopSize = useMediaQuery({
    minWidth: 1024
  });
  const isPwa = getProjectInfo()['projectId'] === 'Pwa';
  const sliderProducts: Array<Product | Outfit> = useMemo(() => products?.filter(product => !isOutfitSlider || ProductUtils.isOutfit(product)), [products]);
  if (!products) {
    return null;
  }
  const updateSliderClass = (swiper: Swiper) => {
    if (!isDesktopSize && !swiper.isBeginning && !swiper.isEnd) {
      swiper.$wrapperEl.addClass(styles.swiperWrapperMobile);
    } else {
      swiper.$wrapperEl.removeClass(styles.swiperWrapperMobile);
    }
  };
  const sliderConfiguration: SliderProps = {
    arrows: hasArrows,
    dots: hasDots && !isMobileSize,
    onSliderMove: updateSliderClass,
    onNavigationNext: updateSliderClass,
    onNavigationPrev: updateSliderClass,
    breakpoints: {
      0: {
        slidesPerView: slidesPerView.mobile
      },
      1024: {
        slidesPerView: !isPwa ? slidesPerView.desktop : slidesPerView.mobile
      }
    }
  };
  const Title = isStartPage ? 'h2' : 'h3';
  if (!sliderProducts?.length) {
    return null;
  }
  return <div className={styles.productSlider} data-sentry-component="ProductSlider" data-sentry-source-file="index.tsx">
      {title && <div className="text-center">
          <Title className="uppercase" style={customTitleStyle}>
            <Markdown text={title} textSize={textSize || 20} disableMargin />
          </Title>
        </div>}
      {ctaLabel && ctaLink && <div className="flex items-center justify-end px-4 sm:px-6 lg:px-0">
          <ReferenceLink target={ctaLink} className="text-accent-400 hidden text-sm font-semibold sm:block">
            {ctaLabel}
            <span aria-hidden="true"> &rarr;</span>
          </ReferenceLink>
        </div>}
      <div className={classnames('relative', {
      'mt-8': !isOutfitSlider,
      'mt-5': isOutfitSlider
    })}>
        <div className="relative -mb-6 w-full overflow-x-auto pb-6" style={{
        '--swiper-navigation-size': `${isTopSellerSlider ? 40 : 100}px`
      } as React.CSSProperties}>
          <Slider spaceBetween={!isTopSellerSlider && isDesktopSize ? 20 : 10} {...sliderConfiguration} data-sentry-element="Slider" data-sentry-source-file="index.tsx">
            {sliderProducts.slice(0, 15).map(product => ProductUtils.isOutfit(product) ? <OutfitProductBox outfit={product} key={product.productId} pageNumber={productListPage} isOutfitSlider tabIndex={tabIndex} fallbackImage={fallbackImage} /> : <ProductBox product={product as Product} supportHover={false} className="!m-0 !max-w-full !border-0 !p-0" key={product.productId} isTopSellerSlider={isTopSellerSlider} onSelect={onSelect} tabIndex={tabIndex} fallbackImage={fallbackImage} />)}
          </Slider>
        </div>
      </div>

      {ctaLabel && ctaLink && <div className="mt-12 flex px-4 sm:hidden">
          <ReferenceLink target={ctaLink} className="text-accent-400 hover:text-accent-500 text-sm font-semibold">
            {ctaLabel}
            <span aria-hidden="true"> &rarr;</span>
          </ReferenceLink>
        </div>}
    </div>;
}