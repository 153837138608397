import React, { useMemo } from 'react';
import classnames from 'classnames';
import { markdown } from 'markdown';
import styles from './markdown.module.scss';
import Unified from '@frontastic-engbers/lib/lib/utils/markdown';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
interface Props {
  text: string;
  textSize?: number;
  textAlign?: 'left' | 'center' | 'right';
  className?: string;
  variables?: object;
  linkToNewTab?: boolean;
  disableMargin?: boolean;
}
const variableRegex = /\{{2}(\w+)}{2}/g;
const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"(?:[^>]*?)>([\s\S]*?)<\/a>/gi;
export const Markdown: React.FC<Props> = ({
  text,
  textSize,
  textAlign,
  className,
  variables = {},
  linkToNewTab,
  disableMargin
}) => {
  const isCommercetoolsLanguageMapper = text && typeof text === 'object' && Object.keys(text)[0].toString().includes('-');
  const {
    t
  } = useI18n(isCommercetoolsLanguageMapper);
  text = text && typeof text === 'object' ? t(text) : text;
  let currentText = useMemo(() => text?.replace(variableRegex, (_, variable) => variables[variable] || ''), [text]);
  if (typeof text !== 'string') {
    return null;
  }
  if (linkToNewTab) {
    currentText = markdown.toHTML(currentText).replace(linkRegex, (match, url, linkText) => {
      return `<a href="${url}" target="_blank">${linkText}</a>`;
    });
  } else {
    currentText = markdown.toHTML(currentText);
  }
  const formatedText: React.ReactNode = Unified.parse(currentText);
  return <div style={{
    fontSize: textSize > 0 ? `${textSize}px` : undefined,
    textAlign
  }} className={classnames('markdown', styles.markdownWrap, className, {
    [styles.customFont]: textSize,
    [styles.disableMargin]: disableMargin
  })} data-sentry-component="Markdown" data-sentry-source-file="index.tsx">
      {React.isValidElement(formatedText) && formatedText.props.children}
    </div>;
};