export class CategoryMapper {
  private static PRODUCT_MAPPING_TABLE: { [key: string]: Record<string, string> } = {
    1000: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Basic regular',
    },
    1010: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Basic slim fit',
    },
    1011: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Basic jersey',
    },
    1020: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Uni regular',
    },
    1030: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Uni slim fit',
    },
    1040: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Kariert regular ',
    },
    1050: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Kariert slim fit',
    },
    1060: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Gestreift regular',
    },
    1070: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Gestreift slim fit',
    },
    1080: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Gemustert regular',
    },
    1090: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Langarm',
      category4: 'Langarm-Hemd Gemustert slim fit',
    },

    1100: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Basic regular',
    },
    1110: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Basic slim fit',
    },
    1111: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Funktion',
    },
    1120: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Uni regular',
    },
    1130: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Uni slim fit',
    },
    1140: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Kariert regular',
    },
    1150: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Kariert slim fit',
    },
    1160: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Gestreift regular',
    },
    1170: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Gestreift slim fit',
    },
    1180: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Gemustert regular',
    },
    1190: {
      category1: 'Mode',
      category2: 'Hemden',
      category3: 'Kurzarm',
      category4: 'Kurzarm-Hemd Gemustert slim fit',
    },

    1200: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Pullover Rundhals',
    },
    1210: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Pullover V-Ausschnitt',
    },
    1220: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Pullover Troyerkragen',
    },
    1230: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Pullover Rollkragen',
    },
    1240: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Pullover Kapuze',
    },
    1250: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Stricksakko',
    },
    1260: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Strickweste',
    },
    1270: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'cardigan',
    },
    1280: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Strickjacke',
    },
    1290: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Pullover',
    },
    1291: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Strick-Polo',
    },
    1300: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Strick',
      category4: 'Sweatjacke Basic',
    },
    1310: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Sweat',
      category4: 'Sweatjacke Rundhals',
    },
    1320: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Sweat',
      category4: 'Sweatjacke V-Ausschnitt',
    },
    1330: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Sweat',
      category4: 'Sweatjacke Kapuze',
    },
    1340: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Sweat',
      category4: 'Sweatjacke Stehbund',
    },
    1350: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Sweat',
      category4: 'Sweatsakko',
    },
    1360: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Sweat',
      category4: 'Sweatshirt Stehbund',
    },
    1370: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Sweat',
      category4: 'Sweatshirt Polokragen',
    },
    1380: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Sweat',
      category4: 'Sweatshirt Kapuze',
    },
    1390: {
      category1: 'Mode',
      category2: 'Strick&Sweat',
      category3: 'Sweat',
      category4: 'Sweatshirt Basic',
    },

    1400: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Shirt Basic',
    },
    1410: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Henley Basic',
    },
    1420: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Shirt Rundhals',
    },
    1430: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Henley',
    },
    1440: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Shirt V-Neck',
    },
    1450: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Henley',
    },
    1460: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Shirt Polokragen',
    },
    1470: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Shirt Troyerkragen',
    },
    1480: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Shirt Rollkragen',
    },
    1490: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Langarm',
      category4: 'Langarm-Shirt Kapuze',
    },

    1500: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Basic',
    },
    1501: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Basic rundhals',
    },
    1502: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Basic rundhals',
    },
    1510: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Henley',
    },
    1520: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Rundhals',
    },
    1530: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Henley',
    },
    1540: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt V-Neck',
    },
    1550: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Henley',
    },
    1560: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Polokragen',
    },
    1570: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Troyerkragen',
    },
    1580: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Rollkragen',
    },
    1590: {
      category1: 'Mode',
      category2: 'Shirts',
      category3: 'Kurzarm',
      category4: 'T-Shirt Kapuze',
    },

    2100: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'Hose 5 Pocket regular',
    },
    2110: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'Hose 5 Pocket slim fit',
    },
    2120: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'chino regular',
    },
    2130: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'chino slim fit',
    },
    2140: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'cargo regular',
    },
    2150: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'cargo slim fit',
    },
    2160: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'Hose Classic regular',
    },
    2170: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'Hose Classic slim fit',
    },
    2180: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'Hose Basic',
    },
    2190: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Hose',
      category4: 'Hose Basic',
    },

    2200: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans 5-Pocket regular',
    },
    2210: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans 5-Pocket straight',
    },
    2220: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans 5-Pocket comfort',
    },
    2230: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans 5-Pocket superstretch',
    },
    2240: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans Chino',
    },
    2250: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans Basic',
    },
    2260: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans Basic',
    },
    2270: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans Basic',
    },
    2280: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans Basic',
    },
    2290: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Jeans',
      category4: 'Jeans Basic',
    },

    2300: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'Short 5 Pocket',
    },
    2310: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'Short Chino',
    },
    2320: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'Short Cargo',
    },
    2330: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'Bermuda 5 Pocket',
    },
    2340: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'Bermuda Chino',
    },
    2350: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'Bermuda Cargo',
    },
    2360: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'capri 5 Pocket',
    },
    2370: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'capri Chino',
    },
    2380: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'capri Cargo',
    },
    2390: {
      category1: 'Mode',
      category2: 'Hosen',
      category3: 'Shorts',
      category4: 'Badeshort',
    },

    2400: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: 'Sakko regular',
    },
    2410: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: 'Sakko slim fit',
    },
    2420: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: 'Freizeitsakko regular',
    },
    2430: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: 'Freizeitsakko slim fit',
    },
    2440: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: '',
    },
    2450: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: '',
    },
    2460: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: '',
    },
    2470: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: '',
    },
    2480: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: 'Sakkoweste',
    },
    2490: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Sakkos',
      category4: 'Sakkoweste slim fit',
    },

    2500: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Outdoor-Jacken',
      category4: 'Jacke kurz',
    },
    2510: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Outdoor-Jacken',
      category4: 'Jacke lang',
    },
    2520: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Outdoor-Jacken',
      category4: 'Weste',
    },
    2530: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Outdoor-Jacken',
      category4: 'Steppjacke_Funktionsjacke',
    },
    2540: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Outdoor-Jacken',
      category4: 'Jeansjacke',
    },
    2550: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Outdoor-Jacken',
      category4: '',
    },
    2560: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Outdoor-Jacken',
      category4: '',
    },
    2570: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Outdoor-Jacken',
      category4: '',
    },
    2580: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Outdoor-Jacken',
      category4: 'Weste',
    },

    2600: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Lederjacken',
      category4: 'Lederjacke kurz',
    },
    2610: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Lederjacken',
      category4: 'Lederjacke lang',
    },
    2620: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Lederjacken',
      category4: 'Lederweste',
    },
    2630: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Lederjacken',
      category4: '',
    },
    2640: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Lederjacken',
      category4: '',
    },
    2650: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Lederjacken',
      category4: '',
    },
    2660: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Lederjacken',
      category4: '',
    },
    2670: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Lederjacken',
      category4: '',
    },
    2680: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Lederjacken',
      category4: '',
    },

    2700: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzugsakko Basic regular',
    },
    2710: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzugsakko Basic slim fit',
    },
    2720: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzugsakko regular',
    },
    2730: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzugsakko slim fit ',
    },
    2740: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzug-Weste',
    },
    2750: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzughose Basic regular',
    },
    2760: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzughose Basic slim fit',
    },
    2770: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzughose regular',
    },
    2780: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzughose slim fit',
    },
    2790: {
      category1: 'Mode',
      category2: 'Business',
      category3: 'Anzüge',
      category4: 'Anzugweste Basic slim fit',
    },

    2800: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Mantel',
      category4: 'Mantel',
    },
    2810: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Mantel',
      category4: 'Mantel',
    },
    2820: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Mantel',
      category4: '',
    },
    2830: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Mantel',
      category4: '',
    },
    2840: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Mantel',
      category4: '',
    },
    2850: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Mantel',
      category4: '',
    },
    2860: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Mantel',
      category4: '',
    },
    2870: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Mantel',
      category4: '',
    },
    2880: {
      category1: 'Mode',
      category2: 'Jacken',
      category3: 'Mantel',
      category4: '',
    },

    3100: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: 'Krawatte Standard',
    },
    3110: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: 'Krawatte Modisch',
    },
    3120: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: 'Schleife Standard',
    },
    3130: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: 'Schleife Modisch',
    },
    3140: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: 'Einstecktuch Standard',
    },
    3150: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: 'Einstecktuch Modisch',
    },
    3160: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: 'Krawattentuch Standard',
    },
    3170: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: 'Krawattentuch Modisch',
    },
    3180: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: '',
    },
    3190: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Krawatten&Fliegen',
      category4: '',
    },

    3200: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: 'Ledergürtel',
    },
    3210: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: 'Ledergürtel',
    },
    3220: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: 'Textilgürtel',
    },
    3230: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: 'Textilgürtel',
    },
    3240: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: 'Gürtel Basic',
    },
    3250: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: 'Gürtel Basic',
    },
    3260: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: 'Gürtel Business',
    },
    3270: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: 'Gürtel Basic',
    },
    3280: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: '',
    },
    3290: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Gürtel',
      category4: '',
    },

    3300: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: 'Socken Basic',
    },
    3310: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: 'Socken Modisch',
    },
    3320: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: 'Sneakersocken Basic',
    },
    3330: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: 'Sneakersocken modisch',
    },
    3340: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: '',
    },
    3350: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: 'Boxershort Basic',
    },
    3360: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: 'Boxershort Modisch',
    },
    3370: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: 'Slip',
    },
    3380: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: 'V Neck Shirt',
    },
    3390: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Wäsche',
      category4: 'Tank Top',
    },

    3400: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Strickschal uni',
    },
    3410: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Strickschal gemustert',
    },
    3420: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Webschal uni',
    },
    3430: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Webschal gemustert',
    },
    3440: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Handschuhe Leder',
    },
    3450: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Handschuhe Textil',
    },
    3460: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Halstuch',
    },
    3470: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Mütze',
    },
    3480: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Hut',
    },
    3490: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Bekleidungs-accessoires',
      category4: 'Base Cap',
    },

    3500: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Tasche',
    },
    3510: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Armband',
    },
    3520: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Sonnenbrille',
    },
    3530: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Schlüsselanhänger',
    },
    3540: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Regenschirm',
    },
    3550: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Portemonnaie',
    },
    3560: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Base Cap',
    },
    3570: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Schuhspary',
    },
    3580: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Schuhspary',
    },
    3590: {
      category1: 'Mode',
      category2: 'Accessoires',
      category3: 'Sonstige Accessoires',
      category4: 'Sonstiges',
    },

    3600: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Sneaker',
      category4: 'Sneaker Low',
    },
    3610: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Sneaker',
      category4: 'Sneaker High',
    },
    3620: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Schnürschuhe',
      category4: 'Halbschuhe Sportiv',
    },
    3630: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Business',
      category4: 'Halbschuhe Classic',
    },
    3640: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Boots',
      category4: 'Boots',
    },
    3650: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Boots',
      category4: 'Stiefeletten',
    },
    3660: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Business',
      category4: 'Business-Schuhe',
    },
    3670: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Sonstige',
      category4: 'Pantoletten',
    },
    3680: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Sonstige',
      category4: 'Zehentrenner',
    },
    3690: {
      category1: 'Mode',
      category2: 'Schuhe',
      category3: 'Sonstige',
      category4: 'Schuhbeutel',
    },
  };

  static getCategoryPath = (goodsGroup: string, isEmilio = false) => {
    const categories = { ...this.PRODUCT_MAPPING_TABLE[goodsGroup] };

    if (isEmilio) {
      categories.category1 = 'Produkte';
    }

    return Object.keys(categories).map((category) => categories[category]);
  };

  static getCategory = (productGroup: string, key: string) => {
    if (!this.PRODUCT_MAPPING_TABLE[productGroup]) {
      return '';
    }

    return this.PRODUCT_MAPPING_TABLE[productGroup][key] ?? '';
  };
}
