import { Block, Button, CountrySelect, LoadingIndicatorInline, Markdown } from '@engbers/components';
import { InputText } from '@engbers/components/shopmacher-ui/input-text';
import { Typography } from '@engbers/components/typography';
import styles from './my-data-form.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { validateDateFormat } from '@frontastic-engbers/helpers/utils/validateDateFormat';
import { validatePostalCode } from '@frontastic-engbers/helpers/utils/validatePostalCode';
import { validateStreetName } from '@frontastic-engbers/helpers/utils/validateStreetName';
import { InputDate } from '@engbers/components/shopmacher-ui/input-date';
import { getProjectInfo, useAccount } from '@frontastic-engbers/lib';
import { Account, AccountUpdateBody } from '@frontastic-engbers/types/account/Account';
import { InputRadioGroup } from '@engbers/components/shopmacher-ui/input-radio-group';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { postcodeValidator } from 'postcode-validator';
type MyDataFormProps = {
  phoneTooltipHeadline?: string;
  phoneTooltipContent?: string;
  phoneUseInfoModal: boolean;
  billingAddressHeadline?: string;
  textSize?: number;
  emailTooltipHeadline?: string;
  emailTooltipContent?: string;
  emailUseInfoModal: boolean;
  birthdateTooltipContent?: string;
  birthdateUseInfoModal: boolean;
};
type MyDataFormState = AccountUpdateBody;
type ValidationErrors = {
  generalError?: string;
} & { [key in keyof MyDataFormState]?: boolean } & {
  birthday?: boolean;
};
type StreetNamePostalCodeErrors = {
  streetName: boolean;
  postalCode: boolean;
  postalCodeCountryNoMatch: boolean;
};
const accountToFormData = (account: Account): MyDataFormState => {
  const address = account?.addresses && account.addresses[0];
  return {
    email: account?.email ?? '',
    salutation: account?.salutation ?? '',
    firstName: account?.firstName ?? '',
    lastName: account?.lastName ?? '',
    addressStreetName: address?.streetName ?? '',
    addressStreetNumber: address?.streetNumber ?? '',
    addressPostalCode: address?.postalCode ?? '',
    addressAdditionalAddress: '',
    addressCity: address?.city ?? '',
    addressCountry: address?.country ?? '',
    phone: address?.phone ?? '',
    birthdayYear: String(account?.birthday?.getFullYear() || 2023),
    birthdayMonth: account?.birthday?.getMonth() ? String('0' + (account.birthday?.getMonth() + 1)).slice(-2) : '01',
    birthdayDay: account?.birthday?.getDate() ? String('0' + account.birthday?.getDate()).slice(-2) : '01'
  };
};
export const MyDataForm: React.FC<MyDataFormProps> = ({
  phoneTooltipHeadline,
  phoneTooltipContent,
  phoneUseInfoModal,
  billingAddressHeadline,
  textSize,
  emailTooltipHeadline,
  emailTooltipContent,
  emailUseInfoModal,
  birthdateTooltipContent,
  birthdateUseInfoModal
}) => {
  const isPwa = getProjectInfo()['projectId'] === 'Pwa';
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    account,
    update,
    updateAccountWithWebservice,
    loggedIn,
    loaded
  } = useAccount();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [data, setData] = useState<MyDataFormState>(accountToFormData(account));
  const [streetNamePostalCodeErrors, setStreetNamePostalCodeErrors] = useState<Partial<StreetNamePostalCodeErrors>>({
    postalCodeCountryNoMatch: false
  });
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({
    salutation: true,
    firstName: true,
    lastName: true,
    addressStreetName: true,
    addressStreetNumber: true,
    addressPostalCode: true,
    addressCity: true,
    addressCountry: true,
    birthday: true
  });
  const {
    pushNotification
  } = useToastNotificationsActions();
  useEffect(() => {
    if (!loaded || !loggedIn) {
      return;
    }
    setData(accountToFormData(account));
  }, [account, loggedIn, loaded]);
  useEffect(() => {
    const updateAccount = async () => {
      await updateAccountWithWebservice(account);
      setDataLoaded(true);
    };
    updateAccount();
  }, []);
  const validatePostalCodeField = (value: string, country: string = null) => {
    const isCountryGermany = country ? country.toLowerCase() === 'de' : data.addressCountry.toLowerCase() === 'de';
    if (isCountryGermany) {
      setStreetNamePostalCodeErrors(state => ({
        ...state,
        postalCode: !validatePostalCode(value)
      }));
    }
    setStreetNamePostalCodeErrors(state => ({
      ...state,
      postalCode: isCountryGermany ? !validatePostalCode(value) : false
    }));
  };
  const validateCountryToPostCode = (countryCode: string, postCode: string) => {
    setStreetNamePostalCodeErrors(state => ({
      ...state,
      postalCodeCountryNoMatch: !postcodeValidator(postCode, countryCode)
    }));
  };
  const validate = (country: string = null) => {
    const isCountryGermany = country ? country.toLowerCase() === 'de' : data.addressCountry?.toLowerCase() === 'de';
    const isCountryIreland = country ? country.toLowerCase() === 'ie' : data.addressCountry?.toLowerCase() === 'ie';
    const errors: ValidationErrors = {
      salutation: data.salutation !== '',
      firstName: data.firstName !== '',
      lastName: data.lastName !== '',
      addressStreetName: data.addressStreetName !== '',
      addressStreetNumber: data.addressStreetNumber !== '',
      addressPostalCode: isCountryIreland || data.addressPostalCode !== '',
      addressCity: data.addressCity !== '',
      addressCountry: data.addressCountry !== '' || !!country,
      birthday: !!data.birthdayYear && !!data.birthdayMonth && !!data.birthdayDay && validateDateFormat(data.birthdayDay.toString(), data.birthdayMonth.toString(), data.birthdayYear.toString())
    };
    setErrors(errors);
    setStreetNamePostalCodeErrors({
      streetName: !validateStreetName(data.addressStreetName),
      postalCode: isCountryGermany ? !validatePostalCode(data.addressPostalCode) : false
    });

    // return a boolean representing the data validity
    // filter using includes, because if any value in the errors object is false then the form is invalid
    return !Object.values(errors).includes(false);
  };
  const handleSubmit = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!loaded || !loggedIn) {
      return;
    }
    const isCountryGermany = data.addressCountry.toLowerCase() === 'de';
    if (!validate() || !validateStreetName(data.addressStreetName) || isCountryGermany && !validatePostalCode(data.addressPostalCode)) {
      return;
    }
    setLoading(true);
    try {
      await update(data);
      pushNotification(formatAccountMessage({
        id: 'account.data.change',
        defaultMessage: 'Your data has been successfully updated.'
      }), 'success');
    } catch (e) {
      setErrors(prev => ({
        ...prev,
        generalError: formatErrorMessage({
          id: 'wentWrong',
          defaultMessage: 'Sorry. Something went wrong..'
        })
      }));
      console.error(e);
    }
    setLoading(false);
  }, [update, data, loaded, loggedIn]);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, [setData]);
  if (!data) {
    return null;
  }
  return <Block marginTop={12} data-sentry-element="Block" data-sentry-component="MyDataForm" data-sentry-source-file="index.tsx">
      <Block marginBottom={12} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        {errors.generalError && <p className="text-encom-400 text-sm">{errors.generalError}</p>}
      </Block>
      {billingAddressHeadline && <Markdown className="mb-4" text={billingAddressHeadline} textSize={textSize} disableMargin />}
      {dataLoaded ? <form>
          <Block marginBottom={4}>
            <InputRadioGroup onChange={id => {
          setData(prev => ({
            ...prev,
            salutation: id as any
          }));
          if (id) {
            setErrors({
              ...errors,
              salutation: true
            });
          }
        }} options={[{
          label: formatMessage({
            id: 'gender.male',
            defaultMessage: 'Herr'
          }),
          id: 'MR'
        }, {
          label: formatMessage({
            id: 'gender.female',
            defaultMessage: 'Frau'
          }),
          id: 'MRS'
        }]} activeId={data.salutation} errorMessage={errors.salutation === false ? formatMessage({
          id: 'salutation'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} />
          </Block>
          <Block marginBottom={4}>
            <InputText placeholder={formatMessage({
          id: 'firstName'
        })} name="firstName" type="text" autoComplete="given-name" required errorMessage={errors.firstName === false ? formatMessage({
          id: 'firstName'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} onBlur={() => setErrors({
          ...errors,
          firstName: data.firstName !== ''
        })} onChange={handleChange} value={data.firstName} />
          </Block>
          <Block marginBottom={4}>
            <InputText id="last_name" name="lastName" type="text" placeholder={formatMessage({
          id: 'lastName'
        })} autoComplete="family-name" required value={data.lastName} errorMessage={errors.lastName === false ? formatMessage({
          id: 'lastName'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} onBlur={() => setErrors({
          ...errors,
          lastName: data.lastName !== ''
        })} onChange={handleChange} />
          </Block>
          <Block marginBottom={4}>
            <InputDate label={formatMessage({
          id: 'birthDate'
        })} disableValidatioOnBlur onChange={value => setData(prev => ({
          ...prev,
          ...value
        }))} birthdayDay={data.birthdayDay || ''} birthdayMonth={data.birthdayMonth || ''} birthdayYear={data.birthdayYear || ''} required isFormatValid={valid => setErrors({
          ...errors,
          birthday: valid
        })} stillHasError={errors && errors.birthday === false} info={birthdateTooltipContent} useInfoModal={birthdateUseInfoModal} />
          </Block>
          <Block marginBottom={4}>
            <div className={styles.splitRow}>
              <InputText id="address_street_name" name="addressStreetName" type="text" autoComplete="" required value={data.addressStreetName} placeholder={formatMessage({
            id: 'street.name'
          })} errorMessage={errors.addressStreetName === false ? formatMessage({
            id: 'street.name'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : streetNamePostalCodeErrors.streetName ? formatErrorMessage({
            id: 'streetNameCharacters',
            defaultMessage: 'Straße darf keine Zahlen enthalten!'
          }) : undefined} onBlur={e => {
            setErrors({
              ...errors,
              addressStreetName: data.addressStreetName !== ''
            });
            setStreetNamePostalCodeErrors(state => ({
              ...state,
              streetName: !validateStreetName(e.target.value)
            }));
          }} onChange={e => {
            handleChange(e);
            setStreetNamePostalCodeErrors(state => ({
              ...state,
              streetName: !validateStreetName(e.target.value)
            }));
          }} />
              <InputText id="address_street_number" name="addressStreetNumber" type="text" autoComplete="" required placeholder={formatMessage({
            id: 'street.number'
          })} value={data.addressStreetNumber} errorMessage={errors.addressStreetNumber === false ? formatMessage({
            id: 'street.number'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : undefined} onBlur={() => setErrors({
            ...errors,
            addressStreetNumber: data.addressStreetNumber !== ''
          })} onChange={handleChange} />
            </div>
          </Block>
          <Block marginBottom={4}>
            <div className={styles.splitRow}>
              <InputText id="address_zip" name="addressPostalCode" type="text" placeholder={formatMessage({
            id: 'zipCodeShort'
          })} autoComplete="" required={data.addressCountry?.toLowerCase() !== 'ie'} value={data.addressPostalCode} disableErrorIcon errorMessage={!errors.addressPostalCode ? formatMessage({
            id: 'zipCodeShort'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : streetNamePostalCodeErrors.postalCodeCountryNoMatch ? streetNamePostalCodeErrors.postalCode ? formatErrorMessage({
            id: 'postalCodeLength',
            defaultMessage: 'PLZ muss in Deutschland 5 Zeichen lang sein'
          }) : formatErrorMessage({
            id: 'postalCodeValid',
            defaultMessage: 'Bitte geben Sie eine gültige PLZ ein'
          }) : undefined} onBlur={e => {
            const isCountryIreland = data.addressCountry?.toLowerCase() === 'ie';
            setErrors({
              ...errors,
              addressPostalCode: isCountryIreland || data.addressPostalCode !== ''
            });
            validatePostalCodeField(e.target.value);
            validateCountryToPostCode(data.addressCountry, e.target.value);
          }} onChange={e => {
            handleChange(e);
            validatePostalCodeField(e.target.value);
            validateCountryToPostCode(data.addressCountry, e.target.value);
          }} />
              <InputText id="address_city" name="addressCity" type="text" autoComplete="" placeholder={formatMessage({
            id: 'place'
          })} required errorMessage={!errors.addressCity ? formatMessage({
            id: 'place'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : streetNamePostalCodeErrors.postalCodeCountryNoMatch ? '' : undefined} onBlur={() => setErrors({
            ...errors,
            addressCity: data.addressCity !== ''
          })} value={data.addressCity} onChange={handleChange} successValidation={!streetNamePostalCodeErrors.postalCodeCountryNoMatch} hasError={streetNamePostalCodeErrors.postalCodeCountryNoMatch} />
            </div>
          </Block>
          <Block marginBottom={4}>
            <CountrySelect id="address_country" placeholder={formatMessage({
          id: 'country'
        })} name="addressCountry" autoComplete="" required value={data.addressCountry} onChange={e => {
          if (isPwa && e.target.value === formatMessage({
            id: 'chooseCountry'
          })) {
            setData(prev => ({
              ...prev,
              addressCountry: ''
            }));
          } else {
            const isCountryIreland = e.target.value.toLowerCase() === 'ie';
            setData(prev => ({
              ...prev,
              addressCountry: e.target.value
            }));
            validatePostalCodeField(data.addressPostalCode, e.target.value);
            validateCountryToPostCode(e.target.value, data.addressPostalCode);
            setErrors({
              ...errors,
              addressPostalCode: isCountryIreland || data.addressPostalCode !== ''
            });
          }
        }} successValidation={!streetNamePostalCodeErrors.postalCodeCountryNoMatch} errorMessage={isPwa && data.addressCountry === '' ? formatMessage({
          id: 'country'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} />
          </Block>
          <Block marginBottom={4}>
            <InputText id="email_mobile" name="email" type="text" placeholder={formatMessage({
          id: 'emailAddress'
        })} autoComplete="" required isDisabled value={account?.email} infoText={emailTooltipContent} infoTextHeadline={emailTooltipHeadline} useInfoModal={emailUseInfoModal} />
          </Block>
          <Block marginBottom={2}>
            <InputText placeholder={formatMessage({
          id: 'phoneMobile'
        })} name="phone" onChange={handleChange} value={data.phone} infoText={phoneTooltipContent} infoTextHeadline={phoneTooltipHeadline} useInfoModal={phoneUseInfoModal} />
          </Block>
          <Block marginBottom={4}>
            <Typography size="xs">{formatMessage({
            id: 'mandatoryField'
          })}</Typography>
          </Block>
          <Block marginBottom={4}>
            <Button buttonType="submit" size="large" isLoading={loading} label={formatMessage({
          id: 'save'
        })} onClick={handleSubmit} />
          </Block>
        </form> : <LoadingIndicatorInline color={'blue'} />}
    </Block>;
};